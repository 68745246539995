import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Convert a string value back to an int value
   */
  static StringToInt(value: any): number {
    if (!value) {
      value = '0'
    }
    const val = value + ''
    return Number.parseInt(val.replace(/\s/g, ''), 10)
  }

  /**
   * Removes whitespace, other non-digit characters, and tries to convert the string to an integer
   *
   * @param numberString - The string to convert to an integer
   * @return The integer value as a string
   */
  static RemoveNonDigitsFromString(numberString: string | number): string {
    if (!numberString) {
      return ''
    }

    if (typeof numberString === 'number') {
      numberString = Math.ceil(numberString)
      return numberString + ''
    }

    const numberStringWithoutWhiteSpace = numberString.replace(/\s/g, '')
    const intVal = Number.parseInt(numberStringWithoutWhiteSpace, 10) // Removes decimals and leading zeros and text after digits
    if (isNaN(intVal)) {
      return ''
    }
    return intVal + ''
  }

  /**
   * Removes non digits from input field and does a number grouping.
   *
   * @param textNumber - The string to format.
   */
  static AddNumberGrouping(textNumber: string | number): string {
    textNumber = DataService.RemoveNonDigitsFromString(textNumber)
    if (textNumber === '0') {
      return ''
    }
    return textNumber.replace(new RegExp('^(\\d{' + (textNumber.length % 3 ?
      textNumber.length % 3 : 0) + '})(\\d{3})', 'g'), '$1 $2').replace(/(\d{3})/gi, '$1 ').trim()
  }
}
